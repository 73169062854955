import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus, FaEye, FaDownload, FaArrowRight, FaUserGraduate, FaSchool } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { FiTrendingDown, FiTrendingUp } from 'react-icons/fi';
import { Input, Button, Form, Space, Table, Divider, notification, Modal, Tag, Row, Col, Menu, Select, DatePicker, Spin, Card, Statistic } from 'antd';
import { CheckOutlined, DownloadOutlined, FileExcelOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DownloadTableExcel } from 'react-export-table-to-excel';
const { Option } = Select;

function GestaoDividas() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedKey, setSelectedKey] = useState('1')
  const [dataPaid, setDataPaid] = useState([]);
  const [dataNotPaid, setDataNotPaid] = useState([]);
  const [searchValue, setSearchValue] = useState({
    nid: '',
    nome: '',
    contacto: '',
  });
  const tableRef = useRef();

  useEffect(() => {
    localStorage.setItem('title', 'Dívidas Académicas');
    localStorage.setItem('type', '2');
    getData(selectedDateRange[0], selectedDateRange[1]);
  }, [])

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  }

  //Obter dados
  const getData = async (start, end) => {
    setLoading(true);
    setDataPaid([]);
    setDataNotPaid([]);

    axios.all([
      axios({
        method: "post",
        url: `https://api.sigf.uem.mz/api/siga/searchFacturasGenerico?orgaoId=${JSON.parse(sessionStorage.getItem('user'))?.unidade?.id_sigf}&dataInicio=${(start).format('YYYY-MM-DD')}&dataFim=${(end).format('YYYY-MM-DD')}`,
        headers: { 'X-Auth-Token': 'ht2v6huj096jbr8rmgclosftphb73qgj', 'X-Signature': 'pya38l5d6nekkqtb4f9kk87k0o1v0l90', 'Content-Type': 'application/json' },
      }),
      axios({
        method: "post",
        url: `https://api.sigf.uem.mz/api/siga/searchMatriculas?orgaoId=${JSON.parse(sessionStorage.getItem('user'))?.unidade?.id_sigf}&dataInicio=${(start).format('YYYY-MM-DD')}&dataFim=${(end).format('YYYY-MM-DD')}`,
        headers: { 'X-Auth-Token': 'ht2v6huj096jbr8rmgclosftphb73qgj', 'X-Signature': 'pya38l5d6nekkqtb4f9kk87k0o1v0l90', 'Content-Type': 'application/json' },
      })
    ])
      .then(axios.spread((facturasResponse, matriculasResponse) => {
        if (facturasResponse.status !== 200 || matriculasResponse.status !== 200) {
          throw Error('Error');
        }
        let acum = [];
        acum.push(...filtroDivida(facturasResponse.data));
        acum.push(...filtroDivida(matriculasResponse.data));

        setDataPaid(acum);
        findCourses(acum);
      }))
      .catch(e => {
        setLoading(false);
        notification.error({
          message: 'Erro',
          description: 'Erro ao obter dados',
        });
      }).finally(e => {
        setLoading(false);
      });
  }

  const filtroDivida = (data) => {
    return data.filter(row => {
      if (row.servico === 'INSCRICAO') {
        return row?.estadoFactura?.name !== 'PAGA';
      } else if (row.servico === 'MATRICULA') {
        return row?.estadoMatricula?.name !== 'ACTIVA';
      } else if (row?.servico === 'MENSALIDADE' || row?.servico === 'MULTA') {
        return row?.estadoFactura?.name !== 'PAGA';
      }
    });
  }

  const findCourses = (rows) => {
    const uniqueValues = new Set();
    rows.forEach(row => {
      if (row.servico === 'MATRICULA') {
        if (row.cursoNome) {
          uniqueValues.add(row.cursoNome);
        }
      } else {
        if (row.nomeCurso) {
          uniqueValues.add(row.nomeCurso);
        }
      }
    });
    const uniqueValuesArray = Array.from(uniqueValues);
    const arrayWithIdAndName = uniqueValuesArray.map((item, index) => ({ id: index, name: item }));
    setTypeCourse(arrayWithIdAndName);
  };

  const findPayments = (rows) => {
    const uniqueValues = [...new Set(rows?.map(row => row.forma_pagamento))];
    const arrayWithIdAndName = uniqueValues.map((item, index) => ({ id: index, name: item }));
    setTypePayment(arrayWithIdAndName);
  };

  const uniqueStudents = (rows) => {
    const uniqueValues = [...new Set(rows?.map(row => row.nomeEstudante))];
    return uniqueValues.length;
  };

  const totalRevenue = (rows) => {
    return rows?.reduce((acc, row) => acc + row.valor, 0);
  };

  //Filtros de Search
  const [typePropina, setTypePropina] = useState(
    [
      { label: 'Inscrição', value: 'INSCRICAO' },
      { label: 'Mensalidades', value: 'MENSALIDADE' },
      { label: 'Matricula', value: 'MATRICULA' },
      { label: 'Multa', value: 'MULTA' },
  ]
  );
  const [typeCourse, setTypeCourse] = useState([]);
  const [typePayment, setTypePayment] = useState([]);

  const [selectedsPropina, setSelectedsPropina] = useState([]);
  const [selectedsCourse, setSelectedsCourse] = useState([]);
  const [selectedsPayment, setSelectedsPayment] = useState([]);

  const currentDate = dayjs();
  const startDate = dayjs().subtract(30, 'day');
  const [endDate, setEndDate] = useState(currentDate);
  const [selectedDateRange, setSelectedDateRange] = useState([startDate, currentDate]);

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
    if (dates) {
      getData(dates[0], dates[1]);
    }
  };

  const handlePropinaChange = (value) => {
    setSelectedsPropina(value);
  };

  const handleCourseChange = (value) => {
    setSelectedsCourse(value);
  }

  //Colunas da Tabela
  const columnsTable = [
    { title: 'Código', dataIndex: 'id', align: 'center', key: 'id', sorter: (a, b) => a.id - b.id },
    { title: 'Estudante', dataIndex: 'nomeEstudante', align: 'left', key: 'nomeEstudante' },
    { title: 'Curso', dataIndex: 'curso', align: 'left', key: 'curso', render: (text, row) => row.servico === 'MATRICULA' ? row.cursoNome : row.nomeCurso },
    { title: 'Tipo de Propina', dataIndex: 'servico', align: 'left', key: 'servico' },
    { title: 'Entidade', dataIndex: 'conta', align: 'left', key: 'conta' },
    { title: 'Referencia', dataIndex: 'referencia', align: 'left', key: 'referencia' },
    { title: 'Data', dataIndex: 'data', align: 'left', key: 'data', render: (text) => text.substring(0, 10) },
    { title: 'Valor Em Divida', dataIndex: 'valor', align: 'left', key: 'valor' },
  ];

  const filtered = (data) => {
    return data.filter((item) => {
      return (
        (selectedsPropina.length == 0 || selectedsPropina.includes(item.servico)) &&
        (selectedsCourse.length == 0 || selectedsCourse.includes(item.cursoNome || item.nomeCurso)) &&
        (searchValue.nome == '' || item.nomeEstudante.toLowerCase().includes(searchValue.nome.toLowerCase()))
      );
    });
  }

  return (
    <div className='flex flex-col gap-4'>
      <Spin spinning={loading}>
        <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
          <div className="flex flex-col items-center sm:flex-row gap-2 grid-cols-4">
            <DatePicker.RangePicker
              className="w-full"
              value={selectedDateRange}
              onChange={handleDateChange}
            />
            <Select
              mode="multiple"
              className="w-full"
              placeholder="Seleccione tipo(s) de Propina"
              value={selectedsPropina}
              onChange={handlePropinaChange}
            >
              {typePropina.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
            <Select
              mode="multiple"
              className="w-full"
              placeholder="Seleccione tipo(s) de Curso"
              value={selectedsCourse}
              onChange={handleCourseChange}
            >
              {typeCourse.map((item) => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className='w-full flex flex-wrap items-center mb-2 gap-5 justify-between grid-cols-3'>
            <Col span={8} className="flex-grow">
              <Card bordered={false} className='w-full'>
                <Statistic
                  title="Unidade"
                  value={JSON.parse(sessionStorage.getItem('user'))?.ent}
                  valueStyle={{
                    color: 'green',
                  }}
                  prefix={<FaSchool />}
                />
              </Card>
            </Col>
            <Col className="flex-grow">
              <Card bordered={false} className='w-full'>
                <Statistic
                  title="Número de Estudantes"
                  value={uniqueStudents(filtered(dataPaid))}
                  valueStyle={{
                    color: 'green',
                  }}
                  prefix={<FaUserGraduate />}
                />
              </Card>
            </Col>
            <Col className="flex-grow">
              <Card bordered={false} className='w-full'>
                <Statistic
                  title="Dívida Total"
                  value={totalRevenue(filtered(dataPaid))}
                  valueStyle={{
                    color: 'red',
                  }}
                  suffix="MZN"
                  prefix={<FiTrendingDown />}
                />
              </Card>
            </Col>
          </div>
          <div className="flex flex-col items-center sm:flex-row gap-2">
            <FaSearch className='ml-auto' />
            <Input
              placeholder="Pesquisa por Nome"
              value={searchValue.nome}
              className='sm:w-64'
              onChange={(e) => setSearchValue({ ...searchValue, nome: e.target.value })}
            />
            <Divider type="vertical" />
            <DownloadTableExcel
              filename={`Propinas Dividas`}
              sheet="pagamentos"
              currentTableRef={tableRef.current}
            >
              <Button type="primary" className='bg-green-600' icon={<FileExcelOutlined />}>Excel</Button>
            </DownloadTableExcel>
          </div>
          <Divider className='my-0' />
          {selectedKey == '1' &&
            <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
              <Table
                size='middle'
                columns={columnsTable}
                dataSource={filtered(dataPaid)}
                pagination={{ pageSize: 10 }}
              />
            </div>
          }
          <div className='hidden'>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Estudante</th>
                  <th>Curso</th>
                  <th>Tipo de Propina</th>
                  <th>Entidade</th>
                  <th>Referencia</th>
                  <th>Data</th>
                  <th>Valor Em Divida</th>
                </tr>
              </thead>
              <tbody>
                {filtered(dataPaid).map((row, index) => (
                  <tr key={index}>
                    <td>{row.id}</td>
                    <td>{row.nomeEstudante}</td>
                    <td>{row.servico === 'MATRICULA' ? row.cursoNome : row.nomeCurso}</td>
                    <td>{row.servico}</td>
                    <td>{row.conta}</td>
                    <td>{row.referencia}</td>
                    <td>{row.data}</td>
                    <td>{row.valor}</td>
                  </tr>
                ))}
              </tbody>  
            </table>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default GestaoDividas;