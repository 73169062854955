import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus, FaEye, FaDownload, FaArrowRight, FaUserGraduate, FaSchool } from 'react-icons/fa';
import { FiTrendingUp } from 'react-icons/fi';
import { Input, Button, Form, Space, Table, Divider, notification, Modal, Tag, Row, Col, Menu, Select, DatePicker, Spin, Card, Statistic } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { DownloadTableExcel } from 'react-export-table-to-excel';
const { Option } = Select;

function GestaoPropinas() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [selectedKey, setSelectedKey] = useState('1')
  const [dataPaid, setDataPaid] = useState([]);
  const [dataNotPaid, setDataNotPaid] = useState([]);
  const [searchValue, setSearchValue] = useState({
    nid: '',
    nome: '',
    contacto: '',
  });
  const tableRef = useRef();

  useEffect(() => {
    localStorage.setItem('title', 'Receitas Académicas');
    localStorage.setItem('type', '2');
    getData(selectedDateRange[0], selectedDateRange[1]);
  }, [])

  const handleMenuClick = (e) => {
    setSelectedKey(e.key);
  }

  //Obter dados
  const getData = async (start, end) => {
    setLoading(true);
    setDataPaid([]);
    setDataNotPaid([]);

    axios({
      method: "get",
      url: `${localStorage.getItem('url')}/api/finance/receitas/${JSON.parse(sessionStorage.getItem('user'))?.unidade?.id_sigf}/${(end).format('YYYY-MM-DD')}/${(start).format('YYYY-MM-DD')}/`,
      headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}`, 'Content-Type': 'application/json' },
    }).then((res) => {
      setDataPaid(res.data.receita);
      findCourses(res.data.receita);
      findReceitas(res.data.receita);
      findPayments(res.data.receita);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
      notification.error({
        message: 'Erro',
        description: 'Erro ao obter dados',
      });
    });
  }

  const findCourses = (rows) => {
    const uniqueValues = [...new Set(rows?.map(row => row.curso_nome))];
    const arrayWithIdAndName = uniqueValues.map((item, index) => ({ id: index, name: item }));
    setTypeCourse(arrayWithIdAndName);
  };

  const findReceitas = (rows) => {
    const uniqueValues = [...new Set(rows?.map(row => row.designacao))];
    const arrayWithIdAndName = uniqueValues.map((item, index) => ({ id: index, name: item }));
    setTypePropina(arrayWithIdAndName);
  };

  const findPayments = (rows) => {
    const uniqueValues = [...new Set(rows?.map(row => row.forma_pagamento))];
    const arrayWithIdAndName = uniqueValues.map((item, index) => ({ id: index, name: item }));
    setTypePayment(arrayWithIdAndName);
  };

  const uniqueStudents = (rows) => {
    const uniqueValues = [...new Set(rows?.map(row => row.nome))];
    return uniqueValues.length;
  };

  const totalRevenue = (rows) => {
    return rows.reduce((acc, row) => acc + row.valor, 0);
  };

  //Filtros de Search
  const [typePropina, setTypePropina] = useState([]);
  const [typeCourse, setTypeCourse] = useState([]);
  const [typePayment, setTypePayment] = useState([]);

  const [selectedsPropina, setSelectedsPropina] = useState([]);
  const [selectedsCourse, setSelectedsCourse] = useState([]);
  const [selectedsPayment, setSelectedsPayment] = useState([]);

  const currentDate = dayjs();
  const startDate = dayjs().subtract(30, 'day');
  const [endDate, setEndDate] = useState(currentDate);
  const [selectedDateRange, setSelectedDateRange] = useState([startDate, currentDate]);

  const handleDateChange = (dates) => {
    setSelectedDateRange(dates);
    if (dates) {
      getData(dates[0], dates[1]);
    }
  };

  const handlePropinaChange = (value) => {
    setSelectedsPropina(value);
  };

  const handleCourseChange = (value) => {
    setSelectedsCourse(value);
  }

  //Colunas da Tabela
  const columnsTable = [
    { title: 'Código', dataIndex: 'numero', align: 'center', key: 'numero', sorter: (a, b) => a.numero - b.numero },
    { title: 'Estudante', dataIndex: 'nome', align: 'left', key: 'nome' },
    { title: 'Curso', dataIndex: 'curso_nome', align: 'left', key: 'curso_nome' },
    { title: 'Tipo Propina', dataIndex: 'designacao', align: 'left', key: 'designacao' },
    { title: 'Entidade', dataIndex: 'codigo_entidade', align: 'left', key: 'codigo_entidade' },
    { title: 'Referencia', dataIndex: 'referencia', align: 'left', key: 'referencia' },
    { title: 'Metodo', dataIndex: 'forma_pagamento', align: 'left', key: 'forma_pagamento' },
    { title: 'Data', dataIndex: 'data_pagamento', align: 'left', key: 'data_pagamento', render: (text) => dayjs(text).format('DD-MM-YYYY') },
    { title: 'Valor', dataIndex: 'valor', align: 'left', key: 'valor' },
  ];

  const filtered = (data) => {
    return data.filter((item) => {
      return (
        (selectedsPropina.length == 0 || selectedsPropina.includes(item.designacao)) &&
        (selectedsCourse.length == 0 || selectedsCourse.includes(item.curso_nome)) &&
        (selectedsPayment.length == 0 || selectedsPayment.includes(item.forma_pagamento)) &&
        (searchValue.nome == '' || item.nome.toLowerCase().includes(searchValue.nome.toLowerCase()))
      );
    });
  }

  return (
    <div className='flex flex-col gap-4'>
      <Spin spinning={loading}>
        <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
          <div className="flex flex-col items-center sm:flex-row gap-2 grid-cols-4">
            <DatePicker.RangePicker
              className="w-full"
              value={selectedDateRange}
              onChange={handleDateChange}
            />
            <Select
              mode="multiple"
              className="w-full"
              placeholder="Seleccione tipo(s) de Propina"
              value={selectedsPropina}
              onChange={handlePropinaChange}
            >
              {typePropina.map((item, index) => (
                <Option key={index} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Select
              mode="multiple"
              className="w-full"
              placeholder="Seleccione tipo(s) de Curso"
              value={selectedsCourse}
              onChange={handleCourseChange}
            >
              {typeCourse.map((item) => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Select
              mode="multiple"
              className="w-full"
              placeholder="Seleccione metodos(s) de Pagamento"
              value={selectedsPayment}
              onChange={(value) => setSelectedsPayment(value)}
            >
              {typePayment.map((item) => (
                <Option key={item.id} value={item.name}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </div>
          <div className='w-full flex flex-wrap items-center mb-2 gap-5 justify-between grid-cols-3'>
            <Col span={8} className="flex-grow">
              <Card bordered={false} className='w-full'>
                <Statistic
                  title="Unidade"
                  value={JSON.parse(sessionStorage.getItem('user'))?.ent}
                  valueStyle={{
                    color: 'green',
                  }}
                  prefix={<FaSchool />}
                />
              </Card>
            </Col>
            <Col className="flex-grow">
              <Card bordered={false} className='w-full'>
                <Statistic
                  title="Número de Estudantes"
                  value={uniqueStudents(filtered(dataPaid))}
                  valueStyle={{
                    color: 'green',
                  }}
                  prefix={<FaUserGraduate />}
                />
              </Card>
            </Col>
            <Col className="flex-grow">
              <Card bordered={false} className='w-full'>
                <Statistic
                  title="Receita Total"
                  value={totalRevenue(filtered(dataPaid))}
                  valueStyle={{
                    color: 'green',
                  }}
                  suffix="MZN"
                  prefix={<FiTrendingUp />}
                />
              </Card>
            </Col>
          </div>
          <div className="flex flex-col items-center sm:flex-row gap-2">
            <FaSearch className='ml-auto' />
            <Input
              placeholder="Pesquisa por Nome"
              value={searchValue.nome}
              className='sm:w-64'
              onChange={(e) => setSearchValue({ ...searchValue, nome: e.target.value })}
            />
            <Divider type="vertical" />
            <DownloadTableExcel
              filename="propinas"
              sheet="pagamentos"
              currentTableRef={tableRef.current}
            >
              <Button type="primary" className='bg-green-600' icon={<FileExcelOutlined />}>Excel</Button>
            </DownloadTableExcel>
          </div>
          <Divider className='my-0' />
          {selectedKey == '1' &&
            <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
              <Table
                size='middle'
                className='custom-table'
                columns={columnsTable}
                dataSource={filtered(dataPaid)}
                pagination={{ pageSize: 10 }}
              />
            </div>
          }
          <div className='hidden'>
            <table ref={tableRef}>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Estudante</th>
                  <th>Curso</th>
                  <th>Tipo Propina</th>
                  <th>Entidade</th>
                  <th>Referencia</th>
                  <th>Metodo</th>
                  <th>Data</th>
                  <th>Valor</th>
                </tr>
              </thead>
              <tbody>
                {filtered(dataPaid).map((row, index) => (
                  <tr key={index}>
                    <td>{row.numero}</td>
                    <td>{row.nome}</td>
                    <td>{row.curso_nome}</td>
                    <td>{row.designacao}</td>
                    <td>{row.codigo_entidade}</td>
                    <td>{row.referencia}</td>
                    <td>{row.forma_pagamento}</td>
                    <td>{dayjs(row.data_pagamento).format('DD-MM-YYYY')}</td>
                    <td>{row.valor}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default GestaoPropinas;