import axios from 'axios';
import { notification } from 'antd';

export const getProfilePicture = async () => {
    await axios.get(localStorage.getItem('url') + '/api/user/picture/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`,
            },
            responseType: 'blob'
        })
        .then(response => {
            sessionStorage.setItem('profile', URL.createObjectURL(response.data))
        }).catch(err => {
            
        }
        )
}

export const getUnits = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/organograma/unit/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar estâncias'
            })
        })
    return dados;
}

export const getUnit = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/organograma/unit/' + id + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar estância'
            })
        })
    return dados;
}

export const getUsers = async (tipo) => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/user/admin/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params: {
                type: tipo
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar usuários'
            })
        })
    return dados;
}

export const getUser = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/user/' + id + '/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar usuário'
            })
        })
    return dados;
}

export const getHistory = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/user/history/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }).catch(err => {
            notification.error({
                message: 'Erro ao buscar historico'
            })
        })
    return dados;
}

export const getTypeDistribution = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/distribution/type/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar tipos de distribuição'
            })
        })
    return dados;
}

export const getPeriodDistribution = async () => {
    let dados = []
    await axios.get(localStorage.getItem('url') + '/api/distribution/period/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: 'Erro ao buscar periodos de distribuição'
            })
        })
    return dados;
}

export const getDistributions = async (status) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/distribution/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                status:status
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getDistribution = async (id) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/distribution/'+id+'/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getFundRequest = async (status) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/distribution/fundrequest/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                status:status
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getRetentions = async (type, status) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/distribution/retention/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                type:type,
                status:status
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            console.log(err)
        })
    return dados;
}

export const getPlanifications = async (status) => {
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/planification/manage/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            },
            params:{
                status:status
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getPlanification =async(id)=>{
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/planification/'+id+'/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}

export const getCountNotifications =async()=>{
    let dados = {}
    await axios.get(localStorage.getItem('url') + '/api/distribution/count/',
        {
            headers: {
                'Authorization': `Token ${sessionStorage.getItem('token')}`
            }
        }).then(res => {
            dados = res.data
        }
        ).catch(err => {
            notification.error({
                message: err.response.data.message
            })
        })
    return dados;
}